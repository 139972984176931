import { GOLD, NAVY } from '../../GlobalStyles'

import styled from 'styled-components'

export const Container = styled.nav<{ opened: boolean }>`
  background-color: ${NAVY};
  z-index: 1000;

  .ant-menu-vertical:not(.ant-menu-inline-collapsed) {
    width: 180px;
  }

  .ant-menu-item-selected {
    background: rgba(255, 255, 255, 0.1) !important;
    border-left: 2px solid ${GOLD};

    > span {
      color: ${GOLD};
    }
  }

  .ant-menu-item {
    height: 55px !important;
    line-height: 55px !important;
  }

  @media (max-width: 768px) {
    position: fixed;
    z-index: 1005;
    height: calc(100vh - 80px);
    bottom: 0;
    padding-top: 40px;
    transition: all 50ms ease-in-out;
    width: ${props => (!props.opened ? 0 : '180px')};
    overflow: ${props => (!props.opened ? 'auto' : 'none')};
    .ant-menu {
      transition: 340ms;
      opacity: ${props => (!props.opened ? 0 : 1)};
    }
  }
`

export const OpenMenuButton = styled.button<{ alignLeft?: boolean }>`
  background-color: transparent;
  border: 0;
  text-align: ${props => (props.alignLeft ? 'left' : 'center')};
  padding: 0 20px;
  margin: 80px 0;
  transition: 200ms;
  width: 80px;

  img {
    width: 18px;
    height: 30px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

export const UserWrapper = styled.div`
  display: flex;
  padding: 0 20px;
  align-items: center;
  margin-bottom: 30px;

  img {
    width: 35px;
    height: 35px;
    border-radius: 4px;
    background-color: #ccc;
    margin-right: 10px;
  }

  > div {
    span {
      color: ${GOLD};
      font-weight: 400;
      font-size: 14px;
      line-height: 1;
    }

    p {
      font-size: 14px;
      margin: 0;
      color: #b0bac9;
      line-height: 1;
    }
  }
`

export const CustomIcon = styled.img<{ collapsed: boolean }>`
  padding-right: ${props => (!props.collapsed ? '30px' : '10px')};
  margin-left: -5px;
  transition: 200ms;
`

export const Overlay = styled.div<{ visible: boolean }>`
  display: ${props => (props.visible ? 'block' : 'none')};
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 1000;
  height: calc(100vh - 80px);
  bottom: 0;
`
