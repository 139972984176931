import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Card, Col, Menu, Row, Statistic } from 'antd'

import React from 'react'
import { useHistory } from 'react-router-dom'

const BackofficeDashboard: React.FC = () => {
  const history = useHistory()

  return (
    <div style={{ padding: 30 }}>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={24}>
          <Menu mode="horizontal">
            <Menu.Item onClick={() => history.push('/backoffice/dashboard')}>
              Dashboard
            </Menu.Item>
            <Menu.Item onClick={() => history.push('/backoffice/users')}>
              Users
            </Menu.Item>
            <Menu.Item onClick={() => history.push('/backoffice/projects')}>
              Projects
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Card>
            <Statistic
              title="Users Active"
              value={32}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
              prefix={<ArrowUpOutlined />}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title="Users Inactive"
              value={9}
              precision={0}
              valueStyle={{ color: '#cf1322' }}
              prefix={<ArrowDownOutlined />}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 15 }}>
        <Col span={6}>
          <Card>
            <Statistic
              title="Users VIP"
              value={13}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
              prefix={<ArrowUpOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Users Normal"
              value={19}
              precision={0}
              valueStyle={{ color: '#cf1322' }}
              prefix={<ArrowDownOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Investiment in Week"
              value={11.28}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
              prefix={<ArrowUpOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="New Projects in Week"
              value={2.3}
              precision={2}
              valueStyle={{ color: '#cf1322' }}
              prefix={<ArrowDownOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default BackofficeDashboard
