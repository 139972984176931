import React from 'react'

import { Container } from './styles'

interface Props {
  title: string
  subtitle: string
}

export const PublicBlockContent: React.FC<Props> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <Container>
      <h1>{title}</h1>
      <p>{subtitle}</p>

      {children}
    </Container>
  )
}
