import styled, { createGlobalStyle } from 'styled-components'
import 'swiper/swiper-bundle.min.css'

// Brand Colors
export const NAVY = '#00234B'
export const BLUE_200 = '#E5E4DF'
export const GOLD = '#BD9F6A'
export const GOLD_100 = '#EECC80'

// UI Colors
export const GREEN = '#4D9B5A'
export const RED = '#BD354F'
export const TEAL = '#7DBDBC'
export const PURPLE = '#5B57BD'

// Black And Grays
export const BLACK = '#00152D'
export const BLACK_200 = '#59718C'
export const BLACK_300 = '#95A5B8'
export const GRAY = '#DEE2E8'
export const GRAY_200 = '#EFF3F9'
export const GRAY_300 = '#FCFDFF'

// Content Colors
export const TEXT_COLOR = '#2e384d'
export const TEXT_CONTENT_COLOR = '#8798ad'

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Rubik', serif;
  }
  
  input {
    outline: 0;
  }

  button {
    outline: 0;
    cursor: pointer;
  }

  #root {
    width: 100%;
  }

  h1 {
    @media (max-width: 500px) {
      font-size: 18px !important;
    }
  }

  .address {
    label {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1.125px;
      text-transform: uppercase;
      color: #b0bac9;
      margin-bottom: 6px;
      margin-top: 10px;
    }

    input {
      height: 40px;
      border-radius: 4px;
      background: rgba(224, 231, 255, 0.2) !important;
      border: 1px solid #e3e9ff !important;
      width: 100%;
      border: 0;
      background-color: transparent;
      padding: 0 16px;
      color: ${NAVY};
      font-size: 15px;
      margin-bottom: 6px;
      margin-top: 10px;
    }

    .google-places-autocomplete__suggestions-container {
      background: rgba(224, 231, 255, 0.2) !important;
      border: 1px solid #e3e9ff !important;
      padding: 10px;
      border-radius: 4px;
    }

    .google-places-autocomplete__suggestion {
      border-bottom: 1px solid #e3e9ff !important;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }

  .label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1.125px;
    text-transform: uppercase;
    color: #b0bac9;
    margin-bottom: 6px;
    margin-top:10px;
  }
`

export const ContentContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  padding: 0 45px;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    padding: 0 10px;
  }
`

export const Content = styled.div`
  margin-top: 40px;

  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    color: ${TEXT_COLOR};
    margin-bottom: 41px;
  }
`

export const Table = styled.table`
  width: 100%;

  th,
  td {
    padding: 10px 25px;
  }

  th {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1.125px;
    text-transform: uppercase;
    color: #b0bac9;
  }

  tbody {
    tr {
      border: 1px solid rgba(46, 91, 255, 0.08);
      td {
        color: ${NAVY};
        font-size: 15px;

        &:first-child {
          font-weight: 500;
          white-space: nowrap;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    th,
    td {
      padding: 5px 5px;
      display: initial;
    }

    th {
      font-size: 10px;
    }

    tbody {
      tr {
        border: 1px solid rgba(46, 91, 255, 0.08);
        td {
          color: ${NAVY};
          font-size: 10px;
          display: block;

          &:first-child {
            font-weight: 500;
            white-space: nowrap;
          }
        }
      }
    }
  }
`
