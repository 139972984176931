import history from 'routes/history'

// TODO: Mpver esse file para a pasta "services"
// Login é um serviço, utils é um helper não pode ter functions como "isAuthenticated"

export const TOKEN_KEY = '@t-connect:jwt'
export const USER_KEY = '@t-connect:user'

type Type =
  | 'INVESTOR'
  | 'PROJECT_DEVELOPER'
  | 'INTERMEDIARY'
  | 'CONSULTANT'
  | 'SUPPLIER'
  | 'CITY'

type Access = 'NORMAL' | 'VIP'

export interface User {
  email: string
  full_name: string
  id: number
  value_range: number
  invested: number
  is_active: boolean
  type: Type
  access: Access
  avatar_url: string
  nickname: string
  address: string
  gender: string
  document: string
  agree_terms: boolean
  percapita_income: number
  main_highlights: string
  number_of_inhabitants: number
  current_government: string
  number_of_companies: number
  current_development_interests: string
  //
  segment: any
  city: any
  region: any
  country: any
  // Company
  company_avatar_url: string
  company_name: string
  company_cnpj: string
  company_segment: any
  company_address: string
  company_description: string
}

export const login = (token: string) => localStorage.setItem(TOKEN_KEY, token)

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY)
  history.push('/')
}

export const isAuthenticated = () => {
  return !!localStorage.getItem(TOKEN_KEY)
}

export const getToken = (): string | null => localStorage.getItem(TOKEN_KEY)

export const getName = (): string | null => {
  const isExistUser: string | null = localStorage.getItem(USER_KEY)
  if (!isExistUser) return ''

  const user = JSON.parse(isExistUser)
  const name = user.full_name.split(' ')[0].toLowerCase()
  return name[0].toUpperCase() + name.slice(1)
}

export const getProfile = (): string => {
  const user = getUser()
  switch (user.type) {
    case 'INVESTOR':
      return 'investor'
    case 'PROJECT_DEVELOPER':
      return 'project developer'
    case 'INTERMEDIARY':
      return 'intermediary'
    case 'CONSULTANT':
      return 'consultant'
    case 'SUPPLIER':
      return 'supplier'
    case 'CITY':
      return 'city'
  }
}

export const getInitialsName = (): string | null => {
  const isExistUser: string | null = localStorage.getItem(USER_KEY)
  if (!isExistUser) return ''

  const user = JSON.parse(isExistUser)
  const slipt_name = user.full_name.split(' ')
  const first_name = slipt_name[0].toUpperCase()
  const last_name = slipt_name[slipt_name.length - 1].toUpperCase()

  if (slipt_name.length === 1) return first_name[0]
  return first_name[0] + last_name[0]
}

export const getUser = (): User =>
  JSON.parse(localStorage.getItem(USER_KEY) as string)
