import React, { ButtonHTMLAttributes } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { Container } from './styles'

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  icon?: string
  children?: React.ReactNode
  theme?: 'success'
}

const antIcon = <LoadingOutlined style={{ fontSize: 18, color: '#fff' }} spin />

const Button: React.FC<IProps> = ({ children, loading, theme, ...rest }) => {
  return (
    <Container {...rest} theme={theme}>
      {loading ? <Spin indicator={antIcon} /> : children}
    </Container>
  )
}

export default Button
