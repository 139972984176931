import { NAVY, RED } from '../../GlobalStyles'

import styled from 'styled-components'

export const Container = styled.div<{ hidden: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${props =>
    props.hidden &&
    `
    heigth: 0;
  `}
`

export const InputWrapper = styled.div<{ error: boolean }>`
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  background: rgba(224, 231, 255, 0.2);
  border: 1px solid ${props => (props.error ? RED : '#e3e9ff')};
  width: 100%;

  svg {
    width: 40px;
    color: ${NAVY};
    font-size: 18px;
  }

  input {
    flex: 1;
    border: 0;
    background-color: transparent;
    padding: 0 16px;
    color: ${NAVY};
    font-size: 15px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`
