import { Container, Header, InputWrapper } from './styles'
import React, { useEffect, useRef, useState } from 'react'
import ReactInputMask, { Props as MaskProps } from 'react-input-mask'

import { useField } from '@unform/core'

interface Props extends MaskProps {
  name: string
  label?: string
  header?: React.ReactNode
  prefixIcon?: React.ReactNode
  hidden?: boolean
}

const InputMask: React.FC<any> = ({
  name,
  label,
  header,
  prefixIcon,
  hidden = false,
  ...rest
}) => {
  const inputRef = useRef(null)
  const { fieldName, registerField, defaultValue, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value)
      },
      clearValue(ref: any) {
        ref.setInputValue('')
      },
    })
  }, [fieldName, registerField])

  return (
    <Container hidden={hidden}>
      {header && <Header>{header}</Header>}
      {label && (
        <label className="label" htmlFor={fieldName}>
          {label}
        </label>
      )}

      <InputWrapper error={!!error}>
        {prefixIcon && prefixIcon}
        <ReactInputMask
          ref={inputRef}
          id={fieldName}
          defaultValue={defaultValue}
          {...rest}
        />
      </InputWrapper>
      {/* {error && <span>{error}</span>} */}
    </Container>
  )
}

export default InputMask
