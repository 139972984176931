import React from 'react'
import PublicLayout from 'components/PublicLayout'
import PwaInstallPopupIOS from 'react-pwa-install-ios'

const IOSInstallPage: React.FC = () => {
  return (
    <PublicLayout>
      <PwaInstallPopupIOS
        delay={3}
        lang="en"
        appIcon="https://app.t-connect.io/logo192.png"
      ></PwaInstallPopupIOS>
    </PublicLayout>
  )
}

export default IOSInstallPage
