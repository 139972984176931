import { Container, Header } from './styles'
import { NAVY, RED } from 'GlobalStyles'
import React, { useEffect, useRef } from 'react'
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select'

import { useField } from '@unform/core'

interface Props extends SelectProps<OptionTypeBase> {
  name: string
  label?: string
  options?: {
    value: string | number
    label: string
  }[]
  header?: React.ReactNode
}

const Select: React.FC<any> = ({ name, label, options, header, ...rest }: any) => {
  const selectRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return []
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value)
        }
        if (!ref.state.value) {
          return ''
        }
        return ref.state.value.value
      },
    })
  }, [fieldName, registerField, rest.isMulti])

  return (
    <Container>
      {header && <Header>{header}</Header>}
      {label && <label htmlFor={fieldName}>{label}</label>}
      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        options={options}
        classNamePrefix="react-select"
        {...rest}
        styles={{
          control: _ => ({
            display: 'flex',
            border: '1px solid',
            borderColor: error ? RED : '#e2e8ff',
            borderRadius: 4,
            height: 40,
          }),

          indicatorSeparator: () => ({ width: 0 }),
          dropdownIndicator: () => ({
            color: NAVY,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: 10,
          }),
          option: () => ({
            fontSize: 15,
            color: NAVY,
            padding: '5px 10px',
          }),
        }}
      />
    </Container>
  )
}

export default Select
