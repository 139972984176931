import styled, { css } from 'styled-components'
import { GOLD } from 'GlobalStyles'

const themes: any = {
  info: css`
    background-color: #e6e6f5;
    color: #5b57bd;
  `,
  success: css`
    background-color: #d7e6e1;
    color: #4d9b5a;
  `,
  danger: css`
    background-color: #efede7;
    color: ${GOLD};
  `,
  warn: css`
    background-color: #eedae0;
    color: #e84a50;
  `,
}

interface StyledProps {
  theme: 'success' | 'danger' | 'warn' | 'info'
}

export const Container = styled.button<StyledProps>`
  padding: 10px 15px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.12px;
  text-transform: uppercase;
  border: 0;
  border-radius: 4px;

  ${props => themes[props.theme]}
`
