import axios, { AxiosRequestConfig } from 'axios'
import { getToken } from '../utils/login'

const api = axios.create({
  baseURL: 'https://api.texyconnect.com/',
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = getToken()
  const headers = { ...config.headers }
  if (token) headers.Authorization = `Bearer ${token}`

  return { ...config, headers }
})

export default api
