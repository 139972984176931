import { DontHaveAccount, LabelWrapper, StyledForm, Wrapper } from './styles'
import { Link, useHistory } from 'react-router-dom'
import { TOKEN_KEY, USER_KEY } from 'utils/login'

import Button from 'components/Button'
import CaptionBold from 'components/Typograph/CaptionBold'
import CustomInput from 'components/CustomInput'
import { PublicBlockContent } from 'components/PublicBlockContent'
import PublicLayout from 'components/PublicLayout'
import React from 'react'
import api from 'services/api'
import { message } from 'antd'

const SignInPage: React.FC = () => {
  const history = useHistory()

  async function handleSubmit(body: any) {
    try {
      const { data } = await api.post('auth/login', body)
      await localStorage.setItem(TOKEN_KEY, data.token)
      await localStorage.setItem(USER_KEY, JSON.stringify(data.user))

      if (data.user.type === 'INVESTOR') {
        return history.push('/timeline')
      }

      return history.push('/dashboard')
    } catch (err) {
      message.error('Invalid user or password!')
    }
  }

  return (
    <PublicLayout>
      <Wrapper>
        <PublicBlockContent
          title="Sign in to T-Connect"
          subtitle="Please enter your credentials to proceed."
        >
          <StyledForm onSubmit={handleSubmit}>
            <CustomInput
              required
              name="email"
              type="email"
              label="Email address"
            />
            <LabelWrapper>
              <CaptionBold>Password</CaptionBold>
              {/* <Link to="/forgot">Forgot Password?</Link> */}
            </LabelWrapper>
            <CustomInput required name="password" type="password" />
            <Button type="submit">Sign In</Button>
            <DontHaveAccount>
              Don't have an account? <Link to="choose-profile">Sign Up</Link>
            </DontHaveAccount>
          </StyledForm>
        </PublicBlockContent>
      </Wrapper>
    </PublicLayout>
  )
}

export default SignInPage
