import { Col, Row } from 'antd'
import { PublicBlockContent } from 'components/PublicBlockContent'
import PublicLayout from 'components/PublicLayout'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Profile } from './styles'

type ProfileParam =
  | 'INVESTOR'
  | 'PROJECT_DEVELOPER'
  | 'INTERMEDIARY'
  | 'CONSULTANT'
  | 'SUPPLIER'
  | 'CITY'

export interface ProfileProps {
  title: string
  description: string
  param: ProfileParam
  disabled: boolean
  selected: boolean
  profileIcon:
    | 'investor'
    | 'developer'
    | 'city'
    | 'intermediary'
    | 'consultant'
    | 'supplier'
}

const profiles: ProfileProps[] = [
  {
    title: 'Investor',
    description: 'Your eyes on the world',
    param: 'INVESTOR',
    profileIcon: 'investor',
    disabled: false,
    selected: false,
  },
  {
    title: 'Project developer',
    description: 'Your business "for all to see"',
    param: 'PROJECT_DEVELOPER',
    profileIcon: 'developer',
    disabled: false,
    selected: false,
  },
  {
    title: 'Cities',
    description: 'Towards the future',
    param: 'CITY',
    profileIcon: 'city',
    disabled: false,
    selected: false,
  },
  {
    title: 'Intermediary',
    description: 'Uniting purposes',
    param: 'INTERMEDIARY',
    profileIcon: 'intermediary',
    disabled: true,
    selected: false,
  },
  {
    title: 'Consultant',
    description: 'You supporting countless projects',
    param: 'CONSULTANT',
    profileIcon: 'consultant',
    disabled: true,
    selected: false,
  },
  {
    title: 'Supplier',
    description: 'Your product serving everyone',
    param: 'SUPPLIER',
    profileIcon: 'supplier',
    disabled: true,
    selected: false,
  },
]

const ChoosePlan: React.FC = () => {
  const history = useHistory()

  function handleProfileClick(profile: ProfileProps): void {
    history.push(`signup`, { profile })
  }

  return (
    <PublicLayout>
      <PublicBlockContent
        title="Get started for free"
        subtitle="Choose your profile"
      >
        <Row gutter={[20, 20]}>
          {profiles.map(profile => (
            <Col span={12} key={profile.title}>
              <Profile
                title={profile.title}
                description={profile.description}
                onClick={(): void => handleProfileClick(profile)}
                selected={profile.selected}
                disabled={profile.disabled}
                profileIcon={profile.profileIcon}
              />
            </Col>
          ))}
        </Row>
      </PublicBlockContent>
    </PublicLayout>
  )
}

export default ChoosePlan
