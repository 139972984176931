import { darken } from 'polished'
import styled from 'styled-components'

export const Container = styled.h3`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.125px;
  text-transform: uppercase;
  color: #b0bac9;
  margin: 0;

  :hover {
    color: ${darken(0.03, '#b0bac9')};
  }
`
