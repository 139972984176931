import { GRAY, NAVY } from 'GlobalStyles'
import styled from 'styled-components'

interface Props {
  selected: boolean
  disabled?: boolean
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: ${props => (!props.disabled ? 'pointer' : 'normal')};

  height: 100%;
  max-width: 150px;
  width: 100%;
  border: 1px solid;
  border-color: ${props => {
    if (props.disabled) return '#e6e6e6'

    return props.selected ? '#2E5BFF' : '#E0E7FF'
  }};
  border-radius: 4px;
  padding: 20px 15px;
  position: relative;

  strong {
    text-align: center;
    color: ${props => (props.disabled ? '#999' : '#2e384d')};
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    margin: 10px 0;
  }

  p {
    text-align: center;
    margin: 0;
    color: ${props => (props.disabled ? GRAY : '#8798ad')};
    font-size: 15px;
    line-height: 22px;
  }
`

export const IconWrapper = styled.div<Props>`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${props => (props.disabled ? '#F8F7FC' : '#E6E6F5')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.disabled ? GRAY : NAVY)};
  font-weight: 500;
`

export const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;
  font-size: 12px;
  top: -9px;
  right: -9px;
  border-radius: 50%;
  position: absolute;
  background-color: #00234b;

  svg {
    color: #fff;
  }
`
