import { Col, Empty, notification, Row, Divider } from 'antd'
import NumericField from 'components/NumericField'
import StatusButton from 'components/StatusButton'
import CaptionBold from 'components/Typograph/CaptionBold'
import { Content, ContentContainer } from 'GlobalStyles'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'services/api'
import {
  CountryWrapper,
  Info,
  InfoWrapper,
  ProjectContent,
  ProjectListItem,
  ProjectsStatus,
  TitleWrapper,
} from './styles'

interface ProjectResponseProps {
  id: number
  value: string
  status: 'APPROVED' | 'WAITING'
  project: {
    id: number
    name: string
    short_description: string
    roi: number
    value_range: string
    segment: {
      id: number
      name: string
      is_active: boolean
    }
    city: {
      id: 2
      code: string
      name: string
      region: {
        id: number
        iso_code: string
        name: string
        country: {
          id: number
          code: string
          name: string
          flag_url: string
        }
      }
    }
  }
}

const InvestorProjects: React.FC = () => {
  const history = useHistory()
  const [projects, setProjects] = useState<ProjectResponseProps[]>([])
  const [fetchProject, setFetchProject] = useState<ProjectResponseProps[]>([])

  const [published, setPublished] = useState(true)

  useEffect(() => {
    async function fetchProjects() {
      try {
        const { data } = await api.get('my-investments')

        setFetchProject(data.result)
        setProjects(data.result)
      } catch (e) {
        notification.error({
          message: 'Failed to fetch project',
        })
      }
    }

    fetchProjects()
  }, [])

  return (
    <ContentContainer>
      <Content>
        <TitleWrapper>
          <h1>My Investments</h1>

          <ProjectsStatus>
            <StatusButton
              theme="info"
              onClick={() => setProjects(fetchProject)}
              text="all"
            />
            <StatusButton
              theme="success"
              onClick={() => {
                const projectFilter = fetchProject.filter(
                  (project: ProjectResponseProps) =>
                    project.status === 'APPROVED',
                )

                setProjects(projectFilter)
              }}
              text="accept payment"
            />
            <StatusButton
              theme="danger"
              onClick={() => {
                const projectFilter = fetchProject.filter(
                  (project: ProjectResponseProps) =>
                    project.status === 'WAITING',
                )

                setProjects(projectFilter)
              }}
              text="waiting payment"
            />
          </ProjectsStatus>
        </TitleWrapper>
      </Content>
      {published ? (
        <Row gutter={[24, 24]}>
          {projects && (
            <>
              {projects.map(({ project, value, status }) => (
                <Col
                  md={6}
                  key={project.id}
                  onClick={() => history.push(`/project/${project.id}`)}
                >
                  <ProjectListItem status={status}>
                    <ProjectContent>
                      <strong>{project.name}</strong>
                      <p>{project.short_description}</p>
                      <InfoWrapper>
                        <Info color="#4d9b5a">
                          <strong>
                            <NumericField value={project.value_range} />
                          </strong>
                          <p>Project price</p>
                        </Info>
                        <Info color="#BD9F6A">
                          <strong>{project.roi}%</strong>
                          <p>ROI</p>
                        </Info>
                      </InfoWrapper>
                      <Divider />
                      <InfoWrapper>
                        <Info style={{ width: '100%' }} color="#4d9b5a">
                          <strong>
                            <NumericField value={value} />
                          </strong>
                          <p>Amount Contributed</p>
                        </Info>
                      </InfoWrapper>
                      <CountryWrapper>
                        <div>
                          <CaptionBold>
                            {project.city.region.country.name}
                          </CaptionBold>
                          <p>
                            {project.city.name} - {project.city.region.name}
                          </p>
                        </div>
                      </CountryWrapper>
                    </ProjectContent>
                  </ProjectListItem>
                </Col>
              ))}
            </>
          )}
        </Row>
      ) : (
        <Empty description="0 Project waiting for publish" />
      )}
    </ContentContainer>
  )
}

export default InvestorProjects
