import React from 'react'
import { Row, Col } from 'antd'

import SignBanner from 'components/SignBanner'

import { Container, Content, LogoWrapper, Column } from './styles'
import logoSvg from '../../assets/img/logo.svg'

const PublicLayout: React.FC = ({ children }) => {
  return (
    <Row>
      <Column sm={24} xs={24} md={16} xl={11}>
        <Container>
          <LogoWrapper>
            <img src={logoSvg} alt="Logo" />
          </LogoWrapper>

          <Content>{children}</Content>
        </Container>
      </Column>
      <Col sm={0} xs={0} md={8} xl={13}>
        <SignBanner />
      </Col>
    </Row>
  )
}

export default PublicLayout
