import { Form } from '@unform/web'
import { NAVY } from 'GlobalStyles'
import styled from 'styled-components'

export const StyledForm = styled(Form)`
  display: flex;
  align-items: center;
  margin-top: 40px;

  .content_form {
    width: 100%;

    .custom_input {
      margin-top: 20px;
      width: 100%;
      height: 40px;
    }

    div {
      margin-bottom: 5px;
    }
  }
`

export const HaveAccount = styled.p`
  font-size: 15px;
  line-height: 22px;
  color: #8798ad;
  text-align: center;
  margin-top: 10px;

  a {
    text-decoration: none;
    color: ${NAVY};
  }
`
