import React, { Suspense, lazy } from 'react'
import { Route, Router, Switch } from 'react-router-dom'

import BackofficeDashboard from 'pages/BackofficeDashboard'
import BackofficeProjects from 'pages/BackofficeProjects'
import BackofficeUsers from 'pages/BackofficeUsers'
import ChoosePlan from 'pages/ChooseProfile'
import GuestRoute from './guest'
import Layout from 'components/Layout'
import PrivateRoute from './private'
import SignInPage from 'pages/SignIn'
import SignUpPage from 'pages/SignUp'
import history from 'routes/history'
import PublicLayout from 'components/PublicLayout'
import IOSInstallPage from 'pages/IOSInstall'
import InvestorProjects from 'pages/InvestorProjects'

const ChatPage = lazy(() => import('pages/Chat'))
const DashboardPage = lazy(() => import('pages/Dashboard'))
const ProjectPage = lazy(() => import('pages/Project'))
const ProjectsPage = lazy(() => import('pages/Projects'))
const CreateProject = lazy(() => import('pages/CreateProject'))
const EditProject = lazy(() => import('pages/EditProject'))
const SettingsPage = lazy(() => import('pages/Settings'))
const TimelinePage = lazy(() => import('pages/Timeline'))
const BusinessPlanPage = lazy(() => import('pages/BusinessPlan'))
const InvestorPage = lazy(() => import('pages/Investor'))

const Routes: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <GuestRoute exact path="/ios" component={IOSInstallPage} />
        <GuestRoute exact path={['/', '/signin']} component={SignInPage} />
        <GuestRoute exact path="/choose-profile" component={ChoosePlan} />
        <GuestRoute exact path="/signup" component={SignUpPage} />
        <Suspense fallback={<div>Loading...</div>}>
          <Layout>
            <PrivateRoute exact path="/settings" component={SettingsPage} />
            <PrivateRoute exact path="/dashboard" component={DashboardPage} />
            <PrivateRoute exact path="/projects" component={ProjectsPage} />
            <PrivateRoute
              exact
              path="/projects/new"
              component={CreateProject}
            />
            <PrivateRoute
              exact
              path="/projects/edit/:projectId"
              component={EditProject}
            />
            <PrivateRoute exact path="/project/:id" component={ProjectPage} />
            <PrivateRoute exact path="/chat" component={ChatPage} />
            <PrivateRoute exact path="/timeline" component={TimelinePage} />
            <PrivateRoute
              exact
              path="/business-plan"
              component={BusinessPlanPage}
            />
            <PrivateRoute
              exact
              path="/business-plan/:planId"
              component={BusinessPlanPage}
            />
            <PrivateRoute exact path="/investor/:id" component={InvestorPage} />
            <PrivateRoute
              exact
              path="/my-projects"
              component={InvestorProjects}
            />

            {/* Backoffice */}
            <Route
              exact
              path="/backoffice/dashboard"
              component={BackofficeDashboard}
            />
            <Route exact path="/backoffice/users" component={BackofficeUsers} />
            <Route
              exact
              path="/backoffice/projects"
              component={BackofficeProjects}
            />
          </Layout>
        </Suspense>
      </Switch>
    </Router>
  )
}

export default Routes
