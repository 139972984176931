import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1.125px;
    text-transform: uppercase;
    color: #b0bac9;
    margin-bottom: 6px;
    margin-top: 10px;
  }

  .react-select--is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`
