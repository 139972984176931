import { Form } from '@unform/web'
import { NAVY } from '../../GlobalStyles'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 120px);

  @media (max-width: 767px) {
    justify-content: center;
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
`

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    width: 100%;

    input {
      margin-top: 20px;
      width: 100%;
      height: 40px;
    }

    div {
      margin-bottom: 10px;
    }
  }
`

export const DontHaveAccount = styled.p`
  font-size: 15px;
  line-height: 22px;
  color: #8798ad;
  text-align: center;

  a {
    text-decoration: none;
    color: ${NAVY};
  }
`

export const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  a {
    font-size: 15px;
    line-height: 22px;
    text-decoration: none;
    color: #b0bac9;
  }
`
