import React from 'react'
import { AiOutlineSound } from 'react-icons/ai'
import { FaRegBuilding, FaRegSmile, FaCheck } from 'react-icons/fa'
import { GrChat } from 'react-icons/gr'
import { MdCardTravel } from 'react-icons/md'
import { CheckContainer, Container, IconWrapper } from './styles'

interface Props {
  title: string
  description: string
  selected: boolean
  disabled?: boolean
  icon?: React.ReactNode
  onClick?: () => void
  profileIcon?:
    | 'investor'
    | 'developer'
    | 'city'
    | 'intermediary'
    | 'consultant'
    | 'supplier'
}

const profileIcons = {
  investor: <>$</>,
  developer: <MdCardTravel size={20} />,
  city: <FaRegBuilding size={20} />,
  intermediary: <FaRegSmile size={20} />,
  consultant: <GrChat size={18} />,
  supplier: <AiOutlineSound size={20} />,
}

const InvestorProfile: React.FC<Props> = ({
  title,
  selected,
  icon,
  description,
  onClick,
  disabled,
  profileIcon,
}) => {
  return (
    <Container selected={selected} onClick={onClick} disabled={disabled}>
      <IconWrapper selected={selected} disabled={disabled}>
        {!profileIcon ? icon : profileIcons[profileIcon]}
      </IconWrapper>
      <strong>{title}</strong>
      <p>{description}</p>

      {selected && (
        <CheckContainer>
          <FaCheck size={9} />
        </CheckContainer>
      )}
    </Container>
  )
}

export default InvestorProfile
