/* eslint-disable react/display-name */
import { Button, Col, Menu, Row, Table, Tooltip } from 'antd'
import { DeleteOutlined, DiffOutlined, LikeOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'

import NumericField from 'components/NumericField'
import api from 'services/api'
import { useHistory } from 'react-router-dom'

const BackofficeUsers: React.FC = () => {
  const [dataSource, setDataSource] = useState()

  useEffect(() => {
    async function geProjects() {
      const { data } = await api.get('project')
      const result = data.result.map((d: any) => ({
        key: d.id,
        name: d.name,
        roi: d.roi,
        value: d.value_range,
        segment: d.segment.name,
      }))

      setDataSource(result)
    }

    geProjects()
  }, [])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ROI %',
      dataIndex: 'roi',
      key: 'roi',
    },
    {
      title: 'Segment',
      dataIndex: 'segment',
      key: 'segment',
    },
    {
      title: 'Value',
      render: (r: any) => {
        console.log(r)
        return <NumericField value={r.value} />
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: () => (
        <>
          <Tooltip title="Documents">
            <Button type="primary" icon={<DiffOutlined />} size="small" />
          </Tooltip>
          <Tooltip title="Approved">
            <Button
              type="primary"
              style={{ margin: '0 10px' }}
              icon={<LikeOutlined />}
              size="small"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              size="small"
            />
          </Tooltip>
        </>
      ),
    },
  ]

  const history = useHistory()

  return (
    <div style={{ padding: 30 }}>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={24}>
          <Menu mode="horizontal">
            <Menu.Item onClick={() => history.push('/backoffice/dashboard')}>
              Dashboard
            </Menu.Item>
            <Menu.Item onClick={() => history.push('/backoffice/users')}>
              Users
            </Menu.Item>
            <Menu.Item onClick={() => history.push('/backoffice/projects')}>
              Projects
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      <h1>Users</h1>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  )
}

export default BackofficeUsers
