import styled, { css } from 'styled-components'
import { NAVY } from 'GlobalStyles'

export const Wrapper = styled.header`
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;

  @media (max-width: 568px) {
    .ant-tag {
      display: none;
    }
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;

  @media (max-width: 768px) {
    > img {
      margin-right: -127px;
    }
  }
  @media (max-width: 568px) {
    > img {
      display: none;
    }
  }
`
export const ToggleSidebarVisiblityButton = styled.button`
  display: none;
  background: transparent;
  border: 0;
  @media (max-width: 768px) {
    display: block;
  }
`

export const MenuActions = styled.aside`
  display: flex;
  align-items: center;

  .ant-avatar {
    margin-right: 20px;
  }

  .bell {
    margin-right: 20px;
    font-size: 20px;
  }

  @media (max-width: 568px) {
    button {
      display: none;
      &:first-child {
        display: block;
      }
    }
    .ant-avatar.ant-avatar-circle {
      display: none;
    }
  }
`
export const PageTitle = styled.strong`
  display: none;
  @media (max-width: 568px) {
    display: block;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: ${NAVY};
  }
`

export const Notification = styled.div`
  height: 60px;
  padding: 10px;
  display: flex;
  align-items: center;

  svg {
    font-size: 20px;
    margin-right: 20px;
  }
`

export const MenuButton = styled.button<{ hasNotification?: boolean }>`
  position: relative;
  background-color: none;
  border: 0;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  img {
    background-blend-mode: multiply;
    height: 25px;
  }

  ${props =>
    props.hasNotification &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: -3px;
        right: -3px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        border: 2px solid #fff;
        background-color: #eecc80;
      }
    `}
`
