import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  height: 100vh;
`

export const ApplicationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

export const ApplicationContent = styled.main`
  flex: 1;
  overflow: auto;
  background-color: #eff3f9;
`
