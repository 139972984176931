import styled from 'styled-components'
import { Form } from '@unform/web'
import { NAVY } from 'GlobalStyles'
import InvestorProfile from 'components/InvestorProfile'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
`

export const Plans = styled(Form)`
  height: 100%;
  display: flex;
  align-items: center;

  > div {
    margin-top: -80px;
  }

  h1 {
    font-weight: 300;
    font-size: 34px;
    line-height: 32px;
    color: ${NAVY};
    margin-bottom: 0;
  }

  p {
    font-size: 15px;
    line-height: 22px;
    color: #8798ad;
    margin: 10px 0 45px;
  }
`

export const Profile = styled(InvestorProfile)`
  cursor: pointer;
`
