import React, { useEffect, useState } from 'react'
import { getName, getUser } from 'utils/login'
import { useHistory, useLocation } from 'react-router-dom'

import { Menu } from 'antd'
import MenuIcon from 'assets/img/SideBar/menu_icon.png'
import { NAVY } from 'GlobalStyles'
import dashboardImg from 'assets/img/SideBar/dashboard.png'
import graphImg from 'assets/img/SideBar/graph.png'
import mailImg from 'assets/img/SideBar/mail.png'
import settingsImg from 'assets/img/SideBar/settings.png'
import {
  Container,
  CustomIcon,
  OpenMenuButton,
  UserWrapper,
  Overlay,
} from './styles'

// TODO Refactor this paths strategy
const paths = ['/timeline', '/project', '/chat', '/settings', '/dashboard']

interface Props {
  sidebarVisibility: boolean
  toggleSidebarVisibility: () => void
}

const SideBar: React.FC<Props> = ({
  sidebarVisibility,
  toggleSidebarVisibility,
}) => {
  const history = useHistory()
  const location = useLocation()
  const [currentRoute, setCurrentRoute] = useState<number>(0)

  const [user, setUser] = useState<any>()

  const isBackoffice = location.pathname.includes('backoffice')

  useEffect(() => {
    const user = getUser()
    setUser(user)
  }, [])

  useEffect(() => {
    const index = paths.findIndex(path => location.pathname.includes(path))
    setCurrentRoute(index + 1)
  }, [location.pathname])

  return (
    <>
      {!isBackoffice && (
        <Container opened={!sidebarVisibility}>
          {user && (
            <>
              <OpenMenuButton
                onClick={toggleSidebarVisibility}
                alignLeft={!sidebarVisibility}
              >
                <img src={MenuIcon} alt="Menu" />
              </OpenMenuButton>

              {!sidebarVisibility && (
                <UserWrapper>
                  <img src="" alt="" />
                  <div>
                    <span>{getName()}</span>
                    <p>{user.type}</p>
                  </div>
                </UserWrapper>
              )}
              <Menu
                defaultSelectedKeys={[String(currentRoute)]}
                selectedKeys={[String(currentRoute)]}
                mode="vertical"
                theme="dark"
                inlineCollapsed={sidebarVisibility}
                style={{ backgroundColor: NAVY }}
              >
                <Menu.Item
                  onClick={() => {
                    setCurrentRoute(1)
                    {
                      user.type === 'INVESTOR'
                        ? history.push('/timeline')
                        : history.push('/dashboard')
                    }
                  }}
                  key="1"
                  icon={
                    <CustomIcon
                      collapsed={!sidebarVisibility}
                      src={dashboardImg}
                      alt="Dashboard"
                    />
                  }
                >
                  Overview
                </Menu.Item>
                {(user.type === 'CITY' ||
                  user.type === 'PROJECT_DEVELOPER') && (
                  <Menu.Item
                    key="2"
                    icon={
                      <CustomIcon
                        collapsed={!sidebarVisibility}
                        src={graphImg}
                        alt="Pagename"
                      />
                    }
                    onClick={() => {
                      setCurrentRoute(2)
                      history.push('/projects')
                    }}
                  >
                    My Projects
                  </Menu.Item>
                )}
                {user.type === 'INVESTOR' && (
                  <Menu.Item
                    key="2"
                    icon={
                      <CustomIcon
                        collapsed={!sidebarVisibility}
                        src={graphImg}
                        alt="Pagename"
                      />
                    }
                    onClick={() => {
                      setCurrentRoute(2)
                      history.push('/my-projects')
                    }}
                  >
                    My Projects
                  </Menu.Item>
                )}
                <Menu.Item
                  key="3"
                  icon={
                    <CustomIcon
                      collapsed={!sidebarVisibility}
                      src={mailImg}
                      alt="Pagename"
                    />
                  }
                  onClick={() => history.push('/chat')}
                >
                  Chat
                </Menu.Item>
                <Menu.Item
                  key="4"
                  icon={
                    <CustomIcon
                      collapsed={!sidebarVisibility}
                      src={settingsImg}
                      alt="Pagename"
                    />
                  }
                  onClick={() => history.push('/settings')}
                >
                  Settings
                </Menu.Item>
              </Menu>
            </>
          )}
        </Container>
      )}
      <Overlay visible={!sidebarVisibility} onClick={toggleSidebarVisibility} />
    </>
  )
}

export default SideBar
