import { lighten } from 'polished'
import styled, { css } from 'styled-components'
import { NAVY } from '../../GlobalStyles'

const themes: any = {
  success: css`
    background-color: #4d9b5a;
    &:hover {
      background-color: ${lighten(0.03, '#4d9b5a')};
    }
  `,
}

export const Container = styled.button<{ theme?: 'success' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  font-weight: 500;
  background-color: ${NAVY};
  color: #fff;
  padding: 0 20px;
  height: 40px;
  border-radius: 4px;
  border: 0;
  transition: 200ms;

  &:hover {
    background-color: ${lighten(0.03, NAVY)};
  }

  ${props => themes[props.theme]};
`
