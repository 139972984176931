import Button from 'components/Button'
import { BLACK_300, NAVY } from 'GlobalStyles'
import styled from 'styled-components'

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  h1 {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 768px) {
    display: block;
  }
`

export const ProjectsStatus = styled.div`
  display: flex;
  align-items: center;

  > button {
    margin-left: 10px;
  }

  @media only screen and (max-width: 768px) {
    margin: 10px 0;
    > button {
      margin: 0 10px 0 0;
    }
  }
`

export const ProjectListItem = styled.div<{ status: 'APPROVED' | 'WAITING' }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  border-left: 2px solid
    ${props => (props.status === 'APPROVED' ? '#4d9b5a' : '#BD9F6A')};
  padding: 30px 30px 20px;
  cursor: pointer;
`

export const ProjectContent = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1000;

  > strong {
    color: ${NAVY};
    font-size: 15px;
    font-weight: 500;
  }

  > p {
    color: ${BLACK_300};
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Info = styled.div`
  width: 50%;

  strong {
    font-weight: 500;
    color: ${props => props.color};
  }

  > p {
    color: ${BLACK_300};
  }
`

export const CountryWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e4f0ff;
  padding: 8px;
  margin-bottom: 20px;

  > img {
    width: 28px;
    height: 18px;
    background: #eee;
    margin-right: 10px;
  }

  p {
    color: ${BLACK_300};
    margin: 0;
  }
`

export const EditProject = styled(Button)`
  background: transparent;
  height: unset;
  margin-top: auto;
  align-self: flex-end;
  z-index: 1005;

  :hover {
    background: transparent;
  }
`
