import styled from 'styled-components'
import { NAVY } from '../../GlobalStyles'
import { Col } from 'antd'

export const Column = styled(Col)`
  height: 100vh;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #9a9a9a;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 330px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 340px) {
    padding: 0 20px;
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    justify-content: center;
  }
`

export const Content = styled.div`
  flex: 1;
  height: calc(100vh - 80px);

  > h1 {
    font-weight: 300;
    font-size: 34px;
    line-height: 32px;
    color: ${NAVY};
    margin-bottom: 0;
  }

  > p {
    font-size: 15px;
    line-height: 22px;
    color: #8798ad;
    margin: 10px 0 45px;
  }
`
