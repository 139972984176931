import { FormHandles, SubmitHandler } from '@unform/core'
import { HaveAccount, StyledForm } from './styles'
import { Link, useHistory, useLocation } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'

import Button from 'components/Button'
import CustomInput from 'components/CustomInput'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import InputMask from 'components/CustomInput/mask'
import InvestorProfile from 'components/InvestorProfile'
import { ProfileProps } from 'pages/ChooseProfile'
import { PublicBlockContent } from 'components/PublicBlockContent'
import PublicLayout from 'components/PublicLayout'
import SelectInput from 'components/SelectInput'
import api from 'services/api'
import { message } from 'antd'

interface Location {
  profile: ProfileProps
}

interface FormData {
  full_name: string
  email: string
  password: string
}

interface InputSelect {
  value: string
  label: string
}

interface SegmentProps {
  id: number
  name: string
  is_active: boolean
}

const SignUpPage: React.FC = () => {
  const formRef = useRef<FormHandles>(null)

  const history = useHistory()
  const { profile } = useLocation().state as Location

  const [address, setAddress] = useState<string>()

  const formData = {
    type: profile.param,
    full_name: '',
    email: '',
    password: '',
    nickname: '',
    telephone: '',
    gender: '',
    birth_date: '',
    address: '',
  }

  const handleSubmit: SubmitHandler<FormData> = async body => {
    try {
      await api.post('auth/register', {
        ...body,
        type: profile.param,
      })

      history.push('/')
    } catch (err) {
      message.error('Oops! An error occurred ')
    }
  }

  return (
    <PublicLayout>
      <PublicBlockContent title="Get started for free" subtitle="Almost there.">
        <InvestorProfile
          title={profile.title}
          description={profile.description}
          profileIcon={profile.profileIcon}
          selected={true}
        />

        <StyledForm
          onSubmit={handleSubmit}
          initialData={formData}
          ref={formRef}
          autoComplete="off"
        >
          <div className="content_form">
            <input type="hidden" name="type" />
            <CustomInput
              className="custom_input"
              required
              name="full_name"
              label="Full name"
            />
            <CustomInput
              className="custom_input"
              name="nickname"
              type="text"
              label="Nickname"
            />
            <div className="address">
              <label>Address</label>
              <GooglePlacesAutocomplete
                placeholder=""
                onSelect={({ description }) => setAddress(description)}
              />
            </div>
            <CustomInput
              className="custom_input"
              type="hidden"
              hidden={true}
              name="address"
              value={address}
            />
            <CustomInput
              className="custom_input"
              required
              name="telephone"
              type="text"
              label="Telephone"
            />
            <SelectInput
              name="gender"
              label="Gender"
              placeholder="Select gender"
              options={[
                {
                  value: 'MALE',
                  label: 'Male',
                },
                {
                  value: 'FEMALE',
                  label: 'Female',
                },
                {
                  value: 'OTHER',
                  label: 'Other',
                },
              ]}
            />
            <InputMask
              mask="9999-99-99"
              className="custom_input"
              required
              name="birth_date"
              type="text"
              label="Birthday"
            />
            <CustomInput
              className="custom_input"
              required
              name="email"
              type="email"
              label="E-mail address"
            />
            <CustomInput
              className="custom_input"
              required
              name="password"
              type="password"
              label="password"
            />
            <Button>Create account</Button>
            <HaveAccount>
              Already have an account? <Link to="signin">Sign In</Link>
            </HaveAccount>
          </div>
        </StyledForm>
      </PublicBlockContent>
    </PublicLayout>
  )
}

export default SignUpPage
