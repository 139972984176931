import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Menu from 'components/Menu'
import SideBar from 'components/Sidebar'
import { ApplicationContent, ApplicationWrapper, Container } from './styles'

const Layout: React.FC = ({ children }) => {
  const location = useLocation()
  const [sidebarVisibility, setSidebarVisibility] = useState(true)

  function toggleSidebarVisibility() {
    setSidebarVisibility(!sidebarVisibility)
  }

  useEffect(() => {
    if (!sidebarVisibility) toggleSidebarVisibility()
  }, [location])

  return (
    <Container>
      <SideBar
        toggleSidebarVisibility={toggleSidebarVisibility}
        sidebarVisibility={sidebarVisibility}
      />
      <ApplicationWrapper>
        <Menu toggleSidebarVisibility={toggleSidebarVisibility} />
        <ApplicationContent>{children}</ApplicationContent>
      </ApplicationWrapper>
    </Container>
  )
}

export default Layout
