import React from 'react'

import { Container } from './styles'

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  theme: 'success' | 'danger' | 'warn' | 'info'
  text: string
}

const StatusButton: React.FC<Props> = ({ theme, text, ...rest }) => {
  return (
    <Container theme={theme} {...rest}>
      {text}
    </Container>
  )
}

export default StatusButton
