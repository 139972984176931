import React from 'react'

import { Container } from './styles'
import signBanner from '../../assets/img/sign_banner.png'

const SignBanner: React.FC = () => {
  return <Container src={signBanner} alt="Sign banner" />
}

export default SignBanner
