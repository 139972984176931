import React, { useEffect, useState } from 'react'
import { Avatar, Menu as Drop, Dropdown, Tag } from 'antd'
import {
  BellOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import { TOKEN_KEY, USER_KEY, getInitialsName, getUser } from 'utils/login'

import { ContentContainer } from 'GlobalStyles'
import logo from 'assets/img/logo.svg'
import IconMenuMobile from 'assets/img/Menu/menu-mobile.svg'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Content,
  MenuActions,
  Notification,
  Wrapper,
  ToggleSidebarVisiblityButton,
  PageTitle,
} from './styles'

interface Props {
  toggleSidebarVisibility?: () => void
}

const paths = [
  { route: '/timeline', title: 'Timeline' },
  { route: '/project', title: 'Project' },
  { route: '/chat', title: 'Chat' },
  { route: '/settings', title: 'Settings' },
]

const Menu: React.FC<Props> = ({ toggleSidebarVisibility }) => {
  const location = useLocation()
  const history = useHistory()
  const [currentRouteTitle, setCurrentRouteTitle] = useState('')
  const [user, setUser] = useState<any>()

  useEffect(() => {
    const find = paths.find(p => p.route.includes(location.pathname))

    if (find) setCurrentRouteTitle(find.title)
  }, [location])

  useEffect(() => {
    const user = getUser()
    setUser(user)
  }, [])

  const handleLogout = async () => {
    await localStorage.removeItem(TOKEN_KEY)
    await localStorage.removeItem(USER_KEY)

    history.push('/')
  }

  const menu = (
    <Drop>
      {user && (
        <>
          <Drop.Item key="0">
            <Notification>
              <InfoCircleOutlined />
              <div>
                {user.type === 'INVESTOR' ? (
                  <>
                    1000 new projects have just been approved on the platform!
                  </>
                ) : (
                  <>New investors observed their project</>
                )}
                <br />
                <small>1 day ago</small>
              </div>
            </Notification>
          </Drop.Item>
          <Drop.Divider />
          <Drop.Item key="1">
            <Notification>
              <CheckCircleOutlined />
              <div>
                <>Your VIP request has been approved.</>
                <br />
                <small>2 day ago</small>
              </div>
            </Notification>
          </Drop.Item>
          <Drop.Divider />
          <Drop.Item key="3">
            <Notification>
              <CheckCircleOutlined />
              <div>
                {user.type === 'INVESTOR' ? (
                  <>Your negotiation request has been approved.</>
                ) : (
                  <>You received a request from an investor</>
                )}
                <br />
                <small>3 day ago</small>
              </div>
            </Notification>
          </Drop.Item>
        </>
      )}
    </Drop>
  )

  return (
    <Wrapper>
      <ContentContainer>
        <Content>
          <ToggleSidebarVisiblityButton onClick={toggleSidebarVisibility}>
            <img src={IconMenuMobile} alt="Menu" />
          </ToggleSidebarVisiblityButton>
          <img src={logo} alt="t-connect logo" />
          <PageTitle>{currentRouteTitle}</PageTitle>
          <Tag color="gold">BETA VERSION 1.1</Tag>
          <MenuActions>
            {/* <MenuButton>
              <img src={bellIcon} alt="Notification" />
            </MenuButton>
            <MenuButton hasNotification>
              <img src={letterIcon} alt="Notification" />
            </MenuButton> */}
            <Dropdown
              overlay={menu}
              placement="bottomRight"
              trigger={['click']}
            >
              <BellOutlined className={'bell'} />
            </Dropdown>
            <Avatar>{getInitialsName()}</Avatar>
            <LogoutOutlined onClick={handleLogout} />
          </MenuActions>
        </Content>
      </ContentContainer>
    </Wrapper>
  )
}

export default Menu
