import styled from 'styled-components'
import { NAVY } from 'GlobalStyles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-weight: 300;
    font-size: 34px;
    line-height: 32px;
    color: ${NAVY};
    margin-bottom: 0;
  }

  p {
    font-size: 15px;
    line-height: 22px;
    color: #8798ad;
    margin: 10px 0 45px;
  }

  input {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    justify-content: center;

    h1 {
      font-size: 28px;
    }

    h1,
    p {
      text-align: center;
    }
  }
`
