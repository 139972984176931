import { Container, Header, InputWrapper } from './styles'
import React, { useEffect, useRef, useState } from 'react'
import CurrencyInput from 'react-currency-input'

import { useField } from '@unform/core'

interface Props {
  name: string
  accept?: 'number' | 'stringOnly'
  label?: string
  header?: React.ReactNode
  prefixIcon?: React.ReactNode
  hidden?: boolean
  isMoney?: boolean
}

export type InputProps = JSX.IntrinsicElements['input'] & Props

const Input: React.FC<InputProps> = ({
  name,
  label,
  header,
  accept,
  prefixIcon,
  hidden = false,
  isMoney = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  const [value, setValue] = useState(defaultValue ? defaultValue : '')

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
  }, [fieldName, registerField])

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    accept: string | undefined,
  ) => {
    if (!accept) {
      setValue(e.target.value)
      return
    }
    const parsedValue =
      accept === 'number'
        ? e.target.value.replace(/\D/, '')
        : e.target.value.replace(/\d/, '')

    setValue(parsedValue)
  }

  const handleChangeMoney = (
    _event: any,
    _maskedvalue: any,
    _floatvalue: any,
  ) => {
    setValue(_event)
  }

  return (
    <Container hidden={hidden}>
      {header && <Header>{header}</Header>}
      {label && (
        <label className="label" htmlFor={fieldName}>
          {label}
        </label>
      )}

      <InputWrapper error={!!error}>
        {prefixIcon && prefixIcon}
        {isMoney ? (
          <>
            <CurrencyInput
              value={value}
              onChange={(e: any, maskedvalue: any, floatvalue: any) =>
                handleChangeMoney(e, maskedvalue, floatvalue)
              }
              {...rest}
            />
            <input
              id={fieldName}
              ref={inputRef}
              value={value}
              type="hidden"
              {...rest}
            />
          </>
        ) : (
          <input
            id={fieldName}
            ref={inputRef}
            value={value}
            onChange={e => handleInputChange(e, accept)}
            {...rest}
          />
        )}
      </InputWrapper>

      {/* {error && <span>{error}</span>} */}
    </Container>
  )
}

export default Input
