import React from 'react'
import NumberFormat from 'react-number-format'
import { calc, truncComma } from 'utils/number'

interface IProps {
  value: number | string
  displayType?: 'text' | 'input'
  prefix?: string | boolean
}

export const NurmberFormat = (prefix: string | boolean = '') => (
  value: string,
) => {
  const number = Math.trunc(Math.abs(Number(value))).toString()

  const isBillion = number.length > 9
  if (isBillion) {
    return `${prefix}${calc(truncComma(Number(value), 9))} bi`
  }

  const isMillion = number.length > 6
  if (isMillion) {
    return `${prefix}${calc(truncComma(Number(value), 6))} mi`
  }
}

const NumericField: React.FC<IProps> = ({
  value,
  displayType = 'text',
  prefix = '$',
}) => {
  const props: { [k: string]: any } = {
    value: Number(value),
    displayType,
    decimalSeparator: '.',
    thousandSeparator: ',',
    prefix,
  }

  // Apply this NurmberFormat(func) only when the display type is text and greater then million
  if (Number(value).toString().length > 6 && displayType === 'text') {
    props.format = NurmberFormat(prefix)
  }

  return <NumberFormat {...props} />
}

export default NumericField
