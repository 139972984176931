import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { isAuthenticated, getUser } from 'utils/login'

const GuestRoute = ({ component, ...rest }: any) => {
  const routeComponent = (props: any) =>
    !isAuthenticated() ? (
      React.createElement(component, props)
    ) : (
      <>
        {getUser().type === 'INVESTOR' ? (
          <Redirect to={{ pathname: '/timeline' }} />
        ) : (
          <Redirect to={{ pathname: '/dashboard' }} />
        )}
      </>
    )
  return <Route {...rest} render={routeComponent} />
}

export default GuestRoute
